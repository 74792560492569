<template>
  <div class="DashBoard" :style="heightGeral">
    <div v-if="carregando == true" class="carregando">
      <br />
      <br />
      <br />Carregando.. Aguarde
    </div>
    <div>
      <Topo @OpenMenuLateral="menuOpen = true"></Topo>
      <b-row
        :style="
          `background-color:${temaCor.topoApp};color:${temaCor.CorItensCabecalho}`
        "
      >
        <b-col class="container"
          ><h4 class="my-3 ml-3">Cartão Virtual</h4></b-col
        >
      </b-row>
      <!--carousel
        class=""
        :autoplay="true"
        :loop="true"
        :paginationEnabled="false"
        :perPageCustom="[[0, 1]]"
        pagniationPosition="bottom-overlay"
        :paginationPadding="2"
        paginationActiveColor="#000000"
        paginationColor="#fff"
      >
        <slide>
          <img :src="cartaoFrente" class="cartaoImg" />
        </slide>
        <slide>
          <img :src="cartaoVerso" class="cartaoImg" />
        </slide>
      </carousel-->
      <div @click="toggleCard()">
        <transition name="flip">
          <div v-bind:key="flipped" class="cartao">
            <img
              :src="!flipped ? cartaoFrente : cartaoVerso"
              class="cartaoImg"
            />
            <div v-if="flipped" class="informacoes">
              <div
                class="nome"
                :style="`color:${temaCor.CorTextoCartaoVirtual}`"
              >
                {{ nome }}
              </div>
              <div
                class="cpf"
                :style="`color:${temaCor.CorTextoCartaoVirtual}`"
              >
                {{ cpf }}
              </div>
            </div>
          </div>
        </transition>
      </div>
      <MenuRodape
        @OpenMenuLateral="menuOpen = true"
        :decoded="decoded"
      ></MenuRodape>
    </div>
    <MenuLateral
      :decoded="decoded"
      :openMenuLateral="menuOpen"
      @closeMenuLateral="menuOpen = false"
    ></MenuLateral>
  </div>
</template>
<!-- eslint-disable -->
<script>
import jwt from "jsonwebtoken";
import MenuRodape from "./MenuRodape";
import Topo from "./Topo";
import MenuLateral from "./MenuLateral";
import { Carousel, Slide } from "vue-carousel";
import service from "../services";
import moment from "moment";
export default {
  name: "DashBoard",
  props: {
    msg: String,
  },
  data() {
    return {
      decoded: {},
      menuOpen: false,
      flipped: false,
      dados: {},
      temaCor: {},
      carregando: false,
      heightGeral: { height: "375px", backgroundColor: "#e7e7e7" },
      nome: null,
      cpf: null,
      cartaoFrente: null,
      cartaoVerso: null,
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },
  components: { Topo, MenuRodape, MenuLateral, Carousel, Slide },
  methods: {
    toggleCard() {
      this.flipped = !this.flipped;
    },
    formataRegional(id) {
      if (id) return id.toString().padStart(4, "0");
      else return "0000";
    },
    formataId(id) {
      if (id) return id.toString().padStart(6, "0");
      else return "000000";
    },
    formataDesde(data) {
      if (data) return moment(data).format("MM/YYYY");
    },
    formataAno(data) {
      if (data) return moment(data).format("YYYY");
    },
    returnaStyle() {
      var obj = {};
      obj.width = `${this.windowWidth}px`;
      obj.height = `${this.windowHeight - 120}px`;
      if (this.windowWidth > this.windowHeight)
        obj.height = `${this.windowHeight + 300}px`;
      return obj;
    },
    verificaToken() {
      var dadosEmpresa = JSON.parse(localStorage.getItem("dadosEmpresa"));
      const nome = localStorage.getItem("nome");
      const cpf = localStorage.getItem("cpf");
      this.temaCor.topoApp = dadosEmpresa.CorBackgroundCabecalho;
      this.temaCor.CorItensCabecalho = dadosEmpresa.CorItensCabecalho;
      this.temaCor.CorTextoCartaoVirtual = dadosEmpresa.CorTextoCartaoVirtual;
      console.log(this.temaCor);
      this.cartaoFrente = dadosEmpresa.Frente;
      this.cartaoVerso = dadosEmpresa.Verso;
      const token = localStorage.getItem("user-token");
      if (token) {
        this.decoded = jwt.decode(token);
        this.nome = nome;
        this.cpf = cpf;
      }
    },
    getSize() {
      console.log(this.heightGeral);
      this.heightGeral.height = `${screen.height - 10}px`;
      console.log("////////////////////", document.height, screen.height);
      console.log(this.heightGeral);
    },
  },
  mounted() {
    this.verificaToken();
    this.getSize();
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  overflow-x: hidden;
}

.cartao {
  display: block;
  border-radius: 7px;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
  position: relative;
  color: #fff;
  font-weight: 600;
  font-size: 20px;

  will-change: transform;
}

.informacoes {
  margin-top: -100px;
  font-size: 14px;
  text-align: left;
  margin-left: 30px;
}

.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}

/* Form */
.flashcard-form {
  position: relative;
}

.cartaoImg {
  width: 100%;
}
</style>
